import { commonEnvironment } from "./environment-common";

export const environment = {
  ...commonEnvironment,
  botName: "rela",
  backendBaseUrl: "https:///ui-bo-api.relabot-out-prod.probayes.net/v0/",
  opened: false,

  headerLogoUrl: "/assets/specific/arole/avatar-ring.svg",
  reducedBotLogoUrl: "/assets/specific/arole/avatar-reduced-bot.svg",
  reducedBotHasWelcomeText: true,
  messageAvatarUrl: "/assets/specific/arole/avatar.svg",
};
